import axios, { AxiosError, AxiosRequestConfig } from "axios";
import humps from "humps";
import getConfig from "next/config";
import { getSession, signOut } from "next-auth/react";
import qs from "qs";
import { initializeClient as initLegacyClient } from "../../client";
import {
  GCAL_INTEGRATION_REQUIRED_CODE,
  CALENDAR_INTERGRATION_REQUIRED_CODE,
} from "../../../utils/constants";

interface ApiClientConfig {
  token: string;
}

const { publicRuntimeConfig } = getConfig();

export const apiClient = axios.create({
  baseURL: publicRuntimeConfig.API_URL,
  transformResponse: [
    ...(axios.defaults.transformResponse as any),
    (data) => humps.camelizeKeys(data),
  ],
  transformRequest: [
    (data) => humps.decamelizeKeys(data),
    ...(axios.defaults.transformRequest as any),
  ],
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
  },
  headers: { common: { "Content-Type": "application/json" } },
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
});

export const initializeClient = (config: ApiClientConfig) => {
  apiClient.defaults.headers.common = {
    ...apiClient.defaults.headers.common,
    Authorization: `Bearer ${config.token}`,
    "Client-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

const getCurrentToken = () => {
  const header = apiClient.defaults.headers.common.Authorization;
  if (header) {
    return header.toString().split(" ")[1];
  }
  return null;
};

const {
  interceptors: { response },
} = apiClient;

const resetSession = async () => {
  const session = await getSession();
  const accessToken: string = (session as any)?.accessToken;
  const currentToken = getCurrentToken();
  if (accessToken && currentToken !== accessToken) {
    initializeClient({ token: accessToken });
    initLegacyClient({ token: accessToken });
  } else if (!accessToken) {
    signOut();
  }
};

response.use(
  (response) => {
    return response;
  },
  (error) => {
    const isTokenInvalid = error?.response?.data?.errors?.find(
      (err: { code: string; attr: string; detail: string }) =>
        err.code === "token_not_valid"
    );
    if (
      (error.response?.status === 403 || error.response?.status === 401) &&
      isTokenInvalid
    ) {
      resetSession();
    }
    throw error;
  }
);

export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = axios.CancelToken.source();
  const promise = apiClient({ ...config, cancelToken: source.token }).then(
    ({ data }) => data
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };

  return promise;
};

export const customInstanceNoTransform = <T>(
  config: AxiosRequestConfig
): Promise<T> => {
  const source = axios.CancelToken.source();
  const promise = apiClient({
    ...config,
    transformRequest: axios.defaults.transformRequest,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };

  return promise;
};

export const axiosInstance = axios;

export interface ErrorType<Error> extends AxiosError<Error> {}
export default apiClient;
